<template>
  <v-dialog v-model="showing" max-width="85%">
    <v-form @submit.prevent="save()">
      <v-card>
        <v-card-title class="headline" primary-title>
          Influencer filtréring
        </v-card-title>
        <v-card-text>
          <v-container fluid grid-list-xl class="p-0">
            <v-layout wrap>
              <v-flex xs12 sm6>
                <v-switch
                  v-model="iFilters.approved"
                  label="Kun godkendte influencers"
                ></v-switch>

                <v-text-field
                  v-model="iFilters.search"
                  append-icon="search"
                  label="Søgeord"
                  hint="Søger efter fornavn, efternavn og email"
                  persistent-hint
                  clearable
                ></v-text-field>

                <gender-select v-model="iFilters.gender"></gender-select>

                <follower-select
                  v-model="iFilters.min_followers"
                ></follower-select>

                <follower-select
                  v-model="iFilters.max_followers"
                  label="Maximum følger krav"
                ></follower-select>

                <channel-category-select
                  v-model="iFilters.category_ids"
                  :return-object="false"
                ></channel-category-select>
              </v-flex>
              <v-flex xs12 sm6>
                <city-select v-model="iFilters.cities"></city-select>

                <age-gender-select
                  v-model="iFilters.age_genders"
                ></age-gender-select>

                <v-select
                  v-model="iFilters.country_codes"
                  placeholder="Countries"
                  :items="countries"
                  item-text="name"
                  item-value="iso_3166_2"
                  :loading="!countries.length"
                  multiple
                  class="mb-2"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="primary">
            Filtrér
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import GenderSelect from "../../selects/GenderSelect";
import FollowerSelect from "../../selects/FollowerSelect";
import ChannelCategorySelect from "../../selects/ChannelCategorySelect";
import CitySelect from "../../selects/CitySelect";
import AgeGenderSelect from "../../selects/AgeGenderSelect";
import { mapActions } from "vuex";
export default {
  components: {
    AgeGenderSelect,
    CitySelect,
    ChannelCategorySelect,
    FollowerSelect,
    GenderSelect
  },
  props: {
    value: {
      required: true
    },
    filters: {
      required: true
    }
  },
  data: () => ({
    iFilters: {},
    countries: []
  }),
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  watch: {
    filters: {
      handler() {
        this.iFilters = this.cloneDeep(this.filters);
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("core", ["getCountries"]),
    loadActiveCountries() {
      this.getCountries({ active: true }).then(
        countries => {
          this.countries = countries;
        },
        error => {
          console.log(error);
        }
      );
    },
    save() {
      this.$emit("save", this.cloneDeep(this.iFilters));
      this.showing = false;
    }
  },
  created() {
    this.iFilters = this.cloneDeep(this.filters);
    this.loadActiveCountries();
  }
};
</script>
