<template>
  <v-card>
    <v-card-title>
      <div>
        <div class="headline">Influencers</div>
        <!--<span class="grey&#45;&#45;text">Oversigt over din daglige præstation de seneste 30 dage</span>-->
      </div>
      <v-spacer></v-spacer>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <download-excel
            :data="csvData"
            delimiter=";"
            encoding="utf-8"
            type="csv"
            name="make_influence_influencers.csv"
            class="cursor-pointer"
          >
            <v-btn v-on="on" icon outlined color="primary" class="mr-3">
              <v-icon small>fal fa-file-csv</v-icon>
            </v-btn>
          </download-excel>
        </template>
        <span>Download CSV</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            outlined
            color="primary"
            @click="filtersDialog = true"
          >
            <!--<v-icon small>fal fa-cog</v-icon>-->
            <v-icon small>fas fa-filter</v-icon>
          </v-btn>
        </template>
        <span>Filtrér</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <influencer-data-table
        :items="influencers"
        :loading="loading"
        :page="page"
        :total="total"
        @changePage="getInfluencers($event)"
      ></influencer-data-table>
      <influencer-filter-dialog
        v-model="filtersDialog"
        :filters="filters"
        @save="filters = $event"
      ></influencer-filter-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import search_filters_url from "@/helpers/mixins/search_filters_url.js";
import InfluencerDataTable from "../data-tables/InfluencerDataTable";
import InfluencerFilterDialog from "../dialogs/InfluencerFilterDialog";
import DownloadExcel from "vue-json-csv";
import { mapActions } from "vuex";

export default {
  components: { InfluencerFilterDialog, InfluencerDataTable, DownloadExcel },
  mixins: [search_filters_url],
  data: () => ({
    filtersDialog: false,
    filters: {},
    loading: true,
    limit: 15,
    page: 1,
    total: null,
    influencers: []
  }),
  watch: {
    filters: {
      handler: function() {
        this.setUrlSearchFilters(this.filters);
        this.influencers = [];

        this.getInfluencers({ page: 1, sorting: {} });
      },
      deep: true
    }
  },
  computed: {
    csvData() {
      let data = [];

      for (let i = 0; i < this.influencers.length; i++) {
        let influencer = this.influencers[i];
        let influencerChannel = null;

        influencer.channels.forEach(channel => {
          if (
            !influencerChannel ||
            influencerChannel.followers < channel.followers
          ) {
            influencerChannel = channel;
          }
        });

        data.push({
          email: influencer.user.email,
          first_name: influencer.user.first_name,
          last_name: influencer.user.last_name,
          instagram_name: influencerChannel ? influencerChannel.username : "",
          instagram_followers: influencerChannel
            ? influencerChannel.followers
            : ""
        });
      }

      return data;
    }
  },
  methods: {
    ...mapActions("moderator", ["getModeratorInfluencers"]),

    getInfluencers({ page = 1, sorting = {} }) {
      this.loading = true;
      this.page = page;
      this.influencers = [];
      let filters = this.cloneDeep(this.filters);

      const filteredKeys = ["cities", "age_genders"];

      if (filters["category_ids"]) {
        filters["category_ids"] = filters["category_ids"].map(item =>
          Number(item)
        );
      }
      filteredKeys.forEach(key => {
        if (filters[key])
          filters[key] = filters[key].map(({ followers, id }) => ({
            followers,
            id
          }));
      });

      let params = {
        ...filters,
        page: this.page,
        limit: this.limit,
        active_channels: true,
        orderBy: "created_at",
        order: "desc"
      };
      if (sorting.sortBy) {
        (params.orderBy = sorting.sortBy.split(".").pop()),
          (params.order = sorting.sortDesc ? "desc" : "asc");
      }
      this.getModeratorInfluencers(params).then(influencers => {
        this.influencers = influencers.response;
        this.total = influencers.lastPage;
        this.loading = false;
      });
    }
  },
  created() {
    this.filters = this.getUrlSearchFilters();
    if (Object.keys(this.filters).length === 0) {
      this.filters.approved = true;
    }
  }
};
</script>
