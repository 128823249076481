<template>
  <div class="influencer-table">
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :options.sync="pagination"
      :loading="loading"
      :search="search"
      :items-per-page="items.length"
      hide-default-footer
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.name">
            <td>
              <influencer-channel-avatar
                size="32"
                display-class="body-2"
                :user="item.user"
                :channel="item.channels[0]"
              ></influencer-channel-avatar>
            </td>
            <td class="word-no-wrap">
              <router-link
                :to="{ name: 'influencer', params: { id: item.uuid } }"
              >
                {{ item.user.first_name }} {{ item.user.last_name }}
              </router-link>
              <br />
              <img
                width="20"
                :src="'/img/flags/' + item.country.flag"
                class="rounded-sm"
              />
              &nbsp;
              {{ item.gender === "MALE" ? "Mand" : "Kvinde" }} /
              {{ item.birthday | date_to_age }}
            </td>
            <td>{{ item.user.email }}</td>
            <td><a :href="`tel: ${item.user.phone }`">{{ item.user.phone }}</a></td>

            <td>{{ item.followers | friendly_numbers }}</td>
            <td>{{ item.impressions | friendly_numbers }}</td>
            <td>{{ item.reach | friendly_numbers }}</td>
            <td>{{ item.channels.length }}</td>
            <td class="word-no-wrap">
              {{ item.created_at | utc_to_local_datetime }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-pagination
      v-model="currPage"
      class="my-2"
      :length="total"
      :total-visible="7"
      @input="handlePageChange"
    ></v-pagination>
  </div>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import friendly_numbers from "@/helpers/filters/friendly_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import date_to_age from "@/helpers/filters/date_to_age";
import InfluencerChannelAvatar from "../../avatars/InfluencerChannelAvatar";

export default {
  components: { InfluencerChannelAvatar },
  filters: {
    local_numbers,
    friendly_numbers,
    utc_to_local_datetime,
    date_to_age
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    page: {
      type: Number
    },
    total: {
      type: Number
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    search: {
      type: String,
      required: false,
      default: ""
    }
  },
  data: () => ({
    currPage: 1,
    headers: [
      { text: "", sortable: false },
      { text: "Navn", value: "user.first_name" },
      { text: "Email", value: "user.email" },
      { text: "Telefon", value: "user.phone", sortable: false  },
      { text: "Følgere", value: "followers" },
      { text: "Eksponeringer", value: "impressions" },
      { text: "Rækkevidde", value: "reach" },
      { text: "Kanaler", value: "channels" },
      { text: "Oprettelse", value: "user.created_at" }
    ],
    pagination: {}
  }),
  computed: {
    params() {
      let sorting = null;
      if (this.pagination.sortBy) {
        sorting = {
          sortBy: this.pagination.sortBy[0],
          sortDesc: this.pagination.sortDesc[0]
        };
      }

      return sorting;
    },
    filteredItems() {
      return this.items.map(item => {
        item.followers = item.channels.reduce((current, nextObject) => {
          return current + nextObject.followers;
        }, 0);
        item.impressions = item.channels.reduce((current, nextObject) => {
          return current + nextObject.impressions;
        }, 0);
        item.reach = item.channels.reduce((current, nextObject) => {
          return current + nextObject.reach;
        }, 0);
        return item;
      });
    }
  },
  watch: {
    items() {
      this.currPage = this.page;
    },
    params: {
      handler(value) {
        this.$emit("changePage", { page: 1, sorting: value });
      },
      deep: true
    }
  },
  methods: {
    handlePageChange(value) {
      this.$emit("changePage", { page: value, sorting: {} });
    }
  }
};
</script>
<style lang="scss">
.v-data-table__progress {
  th {
    padding: 0 !important;
  }
}
</style>
