<template>
  <v-select
    v-model="selected"
    :items="items"
    :label="label"
    clearable
  ></v-select>
</template>

<script>
export default {
  name: "GenderSelect",
  props: {
    value: {
      required: true
    },
    label: {
      default: "Gender"
    }
  },
  data: () => ({
    items: [
      {
        text: "Male",
        value: "male"
      },
      {
        text: "Female",
        value: "female"
      }
    ]
  }),
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style scoped></style>
