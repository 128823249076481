<template>
  <div>
    <v-autocomplete
      v-model="selected"
      ref="citySelect"
      :items="formattedCities"
      :loading="loading"
      :search-input.sync="search"
      hide-no-data
      hide-selected
      return-object
      item-text="city_name"
      placeholder="Start by searching for a city"
      color="primary"
      chips
      multiple
    >
      <template slot="selection" slot-scope="data">
        <v-chip
          :input-value="data.selected"
          close
          close-icon="fa fa-times"
          class="chip--select-multi"
          @click:close="remove(data.item)"
        >
          {{ data.item.city_name }}
        </v-chip>
      </template>
    </v-autocomplete>

    <v-layout flex  v-for="city in selected" :key="city.id">
      <v-flex xs6 class="mr-4">
        <v-text-field
          :value="city.city_name"
          label="City"
          color="primary"
          disabled
        ></v-text-field>
      </v-flex>
      <v-flex xs6>
        <v-text-field
          v-model="city.followers"
          label="Minimum follower requirements"
          @change="cityChange($event, city)"
          color="primary"
        ></v-text-field>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  props: {
    value: {
      require: true
    }
  },
  data: () => ({
    loading: false,
    cities: [],
    search: "",
    page: 1,
    first: 10
  }),
  computed: {
    selected: {
      get() {
        let result = [];
        if (this.value) {
          this.value.forEach(val => {
            this.getInfluencerChannelCity({ id: val.id }).then(data => {
              this.cities.push(data);
              const formatedData = {
                city_name: data.name,
                id: Number(data.id),
                followers: val.followers
              };
              result.push(formatedData);
            });
          });
        }
        return result;
      },
      set(val) {
        // Sort of a hack to close menu after selecting
        this.$refs.citySelect.isMenuActive = false;
        this.$emit("input", val);
      }
    },
    formattedCities() {
      let cities = this.cities.map(city => {
        return {
          city_name: city.name,
          id: Number(city.id),
          followers: 250
        };
      });
      // If already selected append to cities
      if (this.value && this.value.length > 0) {
        this.value.forEach(function(element) {
          let exists = cities.filter(item => item.id === element.id);
          if (exists.length === 0) {
            cities.push(element);
          }
        });
      }

      return cities;
    }
  },
  methods: {
    ...mapActions("core", [
      "getInfluencerChannelCities",
      "getInfluencerChannelCity"
    ]),
    cityChange(event, item) {
      item.followers = Number(event);
      this.$emit("input", this.selected);
    },
    remove(item) {
      let index = this.selected.map(item => item.id).indexOf(item.id);

      if (index !== -1) {
        this.selected.splice(index, 1);
        this.$emit("input", this.selected);
      }
    }
  },
  watch: {
    search: {
      handler: _.debounce(function() {
        const params = {
          search: this.search,
          first: this.first,
          page: this.page
        };
        if (this.search && this.search.length > 0) {
          this.getInfluencerChannelCities(params).then(({ data }) => {
            this.cities = data;
          });
        }
      }, 500)
    }
  }
};
</script>
